// Generated by Framer (0961f6c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["C5AVASuuJ"];

const serializationHash = "framer-qZqM2"

const variantClassNames = {C5AVASuuJ: "framer-v-1ly6a9u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({bookDiscovery, height, httpsCalendlyComGenflowsDiscoveryCallGenflowsAgency, id, width, ...props}) => { return {...props, K4091Zlcm: bookDiscovery ?? props.K4091Zlcm ?? "Book discovery", WvGtC8OYg: httpsCalendlyComGenflowsDiscoveryCallGenflowsAgency ?? props.WvGtC8OYg} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;httpsCalendlyComGenflowsDiscoveryCallGenflowsAgency?: string;bookDiscovery?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WvGtC8OYg, K4091Zlcm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "C5AVASuuJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={WvGtC8OYg} openInNewTab smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1ly6a9u", className, classNames)} framer-ianwtt`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"C5AVASuuJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "0.5px", "--border-color": "rgba(255, 255, 255, 0.24)", "--border-left-width": "0.5px", "--border-right-width": "0.5px", "--border-style": "solid", "--border-top-width": "0.5px", backgroundColor: "var(--token-87b60bc8-4138-4bbd-949b-102d6d4894df, rgb(0, 107, 255))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTYwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255)))"}}>Get Started</motion.p></React.Fragment>} className={"framer-16s9wdh"} data-framer-name={"Get Started"} fonts={["GF;Open Sans-600"]} layoutDependency={layoutDependency} layoutId={"IH6eEEF0c"} style={{"--extracted-r6o4lv": "var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={K4091Zlcm} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qZqM2.framer-ianwtt, .framer-qZqM2 .framer-ianwtt { display: block; }", ".framer-qZqM2.framer-1ly6a9u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 16px 32px 16px 32px; position: relative; text-decoration: none; width: min-content; }", ".framer-qZqM2 .framer-16s9wdh { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qZqM2.framer-1ly6a9u { gap: 0px; } .framer-qZqM2.framer-1ly6a9u > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-qZqM2.framer-1ly6a9u > :first-child { margin-left: 0px; } .framer-qZqM2.framer-1ly6a9u > :last-child { margin-right: 0px; } }", ".framer-qZqM2[data-border=\"true\"]::after, .framer-qZqM2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 180.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"WvGtC8OYg":"httpsCalendlyComGenflowsDiscoveryCallGenflowsAgency","K4091Zlcm":"bookDiscovery"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPghOwOWmI: React.ComponentType<Props> = withCSS(Component, css, "framer-qZqM2") as typeof Component;
export default FramerPghOwOWmI;

FramerPghOwOWmI.displayName = "BTN Copy 2";

FramerPghOwOWmI.defaultProps = {height: 51, width: 180.5};

addPropertyControls(FramerPghOwOWmI, {WvGtC8OYg: {title: "https://calendly.com/genflows/discovery-call-genflows-agency", type: ControlType.Link}, K4091Zlcm: {defaultValue: "Book discovery", displayTextArea: false, title: "Book discovery", type: ControlType.String}} as any)

addFonts(FramerPghOwOWmI, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4iY1M2xLER.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})